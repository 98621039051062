import React, {Fragment} from 'react'
/* import { navigate } from 'gatsby' */
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

function TOCList() {

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            {/* <div onClick={() => navigate('/Software/iOS/InstarVision/iPhone/DDNS/')} role='button' tabIndex={-1} onKeyDown={() => navigate('/Software/iOS/InstarVision/iPhone/DDNS/')}> */}
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/DDNS/">
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPhone/Icons/QL-Box_iOS_InstarVision_DDNS.webp'
                    alt="DDNS Remote Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Remote Access"
              description="The DDNS Access gives you full access to all your cameras functions. As well as allowing you to access its web user interface through your browser ..."
            />
            {/* </div> */}
          </EuiFlexItem>
          <EuiFlexItem>
            {/* <div onClick={() => navigate('/Software/iOS/InstarVision/iPhone/P2P/')} role='button' tabIndex={-1} onKeyDown={() => navigate('/Software/iOS/InstarVision/iPhone/P2P/')}> */}
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/P2P/">
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPhone/Icons/QL-Box_iOS_InstarVision_P2P.webp'
                    alt="P2P Remote Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Remote Access"
              description="The P2P Access does not give you full access to all your cameras functions. But you don´t need to forward a port inside your router and P2P also works ..."
            />
            {/* </div> */}
          </EuiFlexItem>
          <EuiFlexItem>
           {/*  <div onClick={() => navigate('/Software/iOS/InstarVision/iPhone/Multiview/')} role='button' tabIndex={-1} onKeyDown={() => navigate('/Software/iOS/InstarVision/iPhone/Multiview/')}> */}
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/Multiview/">
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPhone/Icons/QL-Box_iOS_InstarVision_Multiview.webp'
                    alt="P2P Remote Access"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Camera Multiview"
              description="Behalten Sie alle Ihre Kameras im Multiview-Menü im Blick. Klicken Sie auf eine beliebige Kamera, um auf deren Video-Livestream zuzugreifen und sie über Funktionstasten zu steuern. more..."
            />
            {/* </div> */}
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    <EuiSpacer />
    
    <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            {/* <div onClick={() => navigate('/Software/iOS/InstarVision/iPhone/Recording/')} role='button' tabIndex={-1} onKeyDown={() => navigate('/Software/iOS/InstarVision/iPhone/Recording/')}> */}
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/Recording/">
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPhone/Icons/QL-Box_iOS_InstarVision_Recording.webp'
                    alt="Video Recordings"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video Recordings"
              description="Videos recorded to your smartphones memory and videos on your cameras internal SD Card can be accessed through the Recording Menu ..."
            />
            {/* </div> */}
          </EuiFlexItem>
          <EuiFlexItem>
            {/* <div onClick={() => navigate('/Software/iOS/InstarVision/iPhone/Settings/')} role='button' tabIndex={-1} onKeyDown={() => navigate('/Software/iOS/InstarVision/iPhone/Settings/')}> */}
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/iOS/InstarVision/iPhone/Settings/">
                  <StaticImage
                    src='../../../../../images/Software/iOS/InstarVision/iPhone/Icons/QL-Box_iOS_InstarVision_Settings.webp'
                    alt="General App Settings"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="General App Settings"
              description="Passen Sie die globalen Einstellungen Ihrer App und aller Kameras an, erstellen Sie Backups und greifen Sie auf die In-App-Hilfe mit Erklärungen für alle Funktionstasten zu. more ..."
            />
            {/* </div> */}
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    </Fragment>

  );
}

export default TOCList