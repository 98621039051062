import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import TocList from '../../../../../components/Software/iOS/InstarVision/iPhone/TocList-Software-InstarVision-iOS'
import QRCode from '../../../../../components/Software/iOS/InstarVision/iPhone/TocList-Software-InstarVision-iOS-QR'

import NavButtons from '../../../../../components/Software/iOS/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'iPhone App InstarVision',
  description: 'Use our InstarVision iOS app to manage your camera from your iPhone or iPad. Or use one of many supported third-party applications.',
  image: '/images/Search/P_SearchThumb_InstarVision_iOS.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_iOS.webp',
  locationEN: '/en/Software/iOS/InstarVision/iPhone/',
  locationDE: '/de/Software/iOS/InstarVision/iPhone/',
  locationFR: '/fr/Software/iOS/InstarVision/iPhone/'
}


function IVAndroid(props) {
    return (
                
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="InstarVision iPhone App" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>iPhone App</h2>
      </EuiTitle>
      <EuiText>
        <p>To access your IP camera with our iOS iPhone App InstarVision we recommend that you first download the newest version from the <OutboundLink href="https://apps.apple.com/us/app/instarvision/id413109553" target="_blank" rel="noopener noreferrer">Apple Store</OutboundLink>. Use the search word Instar to find our app inside the store.</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiTitle size="m">
        <h3>Overview over App Menus</h3>
      </EuiTitle>
      <TocList />
      <EuiSpacer size="xl" />
      <EuiTitle size="m">
        <h3>Download</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      <QRCode />
      <EuiSpacer size="Xl" />
      <p><em>List of <Link to="/Software/Android/InstarVision/Thirdparty_Cameras/">compatible thirdparty cameras</Link></em></p>
    </React.Fragment>
  )
}

export default IVAndroid